import styles from './Image.module.scss';

type Props = {
  src: string;
  fallback: string;
  alt: string;
  type?: string;
} & typeof defaultProps;

const defaultProps = {
  type: 'image/webp',
};

/**
 * Image
 * @group  Atom
 *
 * @param {string} src - webP image path
 * @param {string} fallback - png fallback path
 * @param {string} alt - alt text
 * @param {string} type - default: 'image/webp'
 * @param delegated
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
/* eslint-disable react/jsx-props-no-spreading */
function Image({ src, fallback, alt, type = 'image/webp', ...delegated }: Props) {
  return (
    <picture className={styles.Image}>
      <source srcSet={src} type={type} height='346px' width='613px' />
      <img src={fallback} alt={alt} height='346px' width='613px' {...delegated} />
    </picture>
  );
}

Image.defaultProps = defaultProps;
export default Image;
