import React, { Suspense, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet-async';

// components
import Header from 'components/organisms/Header/Header';
import Main from 'components/organisms/Main/Main';
import Footer from 'components/organisms/Footer/Footer';
import ErrorBoundaryFallback from 'components/organisms/ErrorBoundary/ErrorBoundaryFallback';
import Loading from 'components/molecules/Loading/Loading';

// fonts
import '@fontsource/asul/latin-400.css';
import '@fontsource/asul/latin-700.css';

import '@fontsource/open-sans/latin-400.css';
import '@fontsource/open-sans/latin-600.css';
import '@fontsource/open-sans/latin-700.css';

import asul400 from '@fontsource/asul/files/asul-latin-400-normal.woff2';
import asul700 from '@fontsource/asul/files/asul-latin-700-normal.woff2';

import openSans400 from '@fontsource/open-sans/files/open-sans-latin-400-normal.woff2';
import openSans600 from '@fontsource/open-sans/files/open-sans-latin-600-normal.woff2';
import openSans700 from '@fontsource/open-sans/files/open-sans-latin-700-normal.woff2';

// configs
import SEO from 'constants/seo';

import ReactGA from 'react-ga4';

// theme
import styles from './App.module.scss';

function App() {
  const [hasError, setHasError] = useState(false);

  const {
    charSet,
    themeColor,
    viewport,
    robots,
    imgHt,
    imgWd,
    type,
    siteDesc,
    siteUrl,
    siteName,
    locale,
    twitterHandle,
    twitterCardType,
  } = SEO;

  const logToErrorLoggingService = (error: Error, componentStack: { componentStack: string }) => {
    /* eslint-disable no-console */
    console.error('Loose wires. Log to service if needed', error, componentStack);
  };

  const MEASUREMENT_ID = 'G-NNC2YSFG96';
  ReactGA.initialize(MEASUREMENT_ID);

  return (
    <>
      <Helmet prioritizeSeoTags>
        <meta charSet={charSet} />
        <meta name='theme-color' content={themeColor} />
        <meta name='viewport' content={viewport} />
        <meta name='description' content={siteDesc} />
        <meta name='robots' content={robots} />

        <meta property='og:locale' content={locale} />
        <meta property='og:site_name' content={siteName} />
        <meta property='og:url' content={siteUrl} />
        <meta property='og:type' content={type} />
        <meta property='og:title' content={siteName} />
        <meta property='og:image:width' content={imgWd} />
        <meta property='og:image:height' content={imgHt} />
        <meta property='twitter:card' content={twitterCardType} />
        <meta property='twitter:site' content={twitterHandle} />

        <link rel='canonical' href={siteUrl} />

        <link
          href={openSans400}
          rel='prefetch'
          as='font'
          type='font/woff2'
          crossOrigin='anonymous'
        />
        <link
          href={openSans600}
          rel='prefetch'
          as='font'
          type='font/woff2'
          crossOrigin='anonymous'
        />
        <link
          href={openSans700}
          rel='prefetch'
          as='font'
          type='font/woff2'
          crossOrigin='anonymous'
        />
        <link href={asul400} rel='prefetch' as='font' type='font/woff2' crossOrigin='anonymous' />
        <link href={asul700} rel='prefetch' as='font' type='font/woff2' crossOrigin='anonymous' />
        <title>{siteName}</title>
      </Helmet>

      <Suspense fallback={<Loading />}>
        <div className={styles.ContainerFluid}>
          <Header />
          <div className={styles.ContentWrapper}>
            <ErrorBoundary
              FallbackComponent={ErrorBoundaryFallback}
              onReset={() => setHasError(false)}
              onError={(error, componentStack) => {
                logToErrorLoggingService(error, componentStack);
              }}
              resetKeys={[hasError]}
            >
              <Main />
            </ErrorBoundary>
          </div>
          <Footer />
        </div>
      </Suspense>
    </>
  );
}

export default App;
