import { useRef, useState } from 'react';

// router
import { useLocation } from 'react-router-dom';

// components
import Navbar from 'components/organisms/Navbar/Navbar';
import SocialIcons from 'components/organisms/SocialIcons/SocialIcons';

// hooks
import useScrollPosition, { ScrollPosHistoryType } from 'hooks/useScrollPosition';

// internationalization
import { useTranslation } from 'react-i18next';

// constants
import { COLORS, SIZES } from 'constants/theme';

// theming
import styles from './Header.module.scss';

function Header() {
  const { t } = useTranslation();
  const location = useLocation();

  const [isHeaderShrunk, setIsHeaderShrunk] = useState<boolean>();
  const headerShrunk = useRef(false);

  const setHeaderShrunkState = (isShrunk: boolean) => {
    headerShrunk.current = isShrunk || false;
    setIsHeaderShrunk(isShrunk);
  };

  useScrollPosition(({ curPos }: ScrollPosHistoryType) => {
    if (curPos) {
      if (curPos.y < 0 && !headerShrunk.current) {
        setHeaderShrunkState(true);
      } else if (curPos.y >= 0 && headerShrunk.current) {
        setHeaderShrunkState(false);
      }
    }
  });

  return (
    <div className={`${styles.Header} ${isHeaderShrunk ? styles.shrink : ''}`}>
      <header>
        <div className={styles.topHeader}>
          <p>{t('header.welcome')}</p>
          <SocialIcons size={SIZES.SMALL} color={COLORS.LIGHT} />
        </div>
        <div className={styles.headerWrap}>
          <Navbar curRoute={location.pathname} />
        </div>
      </header>
    </div>
  );
}

export default Header;
