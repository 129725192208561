import PageHelmet from 'components/seo/Helmet/PageHelmet/PageHelmet';

// components
// import Articles from 'cms/Articles/Articles';
import Image from 'components/atoms/Image/Image';
import AboutUs from 'components/organisms/_content/About/About';

// constants
import { SEO_PAGE_TYPES } from 'constants/seo';

// theming
import styles from './About.module.scss';

function About() {
  const imgData = {
    img: '4-chicagoeverton',
    name: 'Over 20 years of Evertonians in Chicago',
  };

  return (
    <>
      <PageHelmet pageType={SEO_PAGE_TYPES.about} />
      <section className={styles.AboutPage}>
        <Image
          src={`/images/${imgData.img}.webp`}
          fallback={`/images/${imgData.img}.jpg`}
          alt={`screenshot: ${imgData.name}`}
        />
        <AboutUs />
      </section>
      {/*
      // todo - add strapi blog
       <Articles />
      */}
    </>
  );
}

export default About;
