import PageHelmet from 'components/seo/Helmet/PageHelmet/PageHelmet';

// components
import Image from 'components/atoms/Image/Image';
import Welcome from 'components/organisms/_content/Welcome/Welcome';

// constants
import { SEO_PAGE_TYPES } from 'constants/seo';

// theming
import styles from './Home.module.scss';

function Home() {
  const imgData = {
    img: '1-20201017-chicagoeverton-derby-AJHudsons',
    name: 'Merseyside Derby 2021',
  };

  return (
    <>
      <PageHelmet pageType={SEO_PAGE_TYPES.home} />

      <section className={styles.HomePage}>
        <Image
          src={`/images/${imgData.img}.webp`}
          fallback={`/images/${imgData.img}.jpg`}
          alt={`screenshot: ${imgData.name}`}
        />
        <Welcome />
      </section>
    </>
  );
}

export default Home;
