import { Route, Routes } from 'react-router-dom';
import AppRoutes from 'router/routes';
import PageNotFound404 from 'components/pages/404/404';

/* TODO -- add strapi blog
import Articles from 'cms/Articles/Articles';
import Article from 'cms/Article/Article';
import Category from 'cms/Category/Category';
*/
function Router() {
  const routingComponents = AppRoutes.map(({ id, path, component }) => {
    return <Route key={id} path={path} element={component} />;
  });

  return (
    <Routes>
      {routingComponents}
      <Route path='*' element={<PageNotFound404 />} />
      {/*
      <Route path='/articles' element={<Articles />} />
      <Route path='/article/:slug' element={<Article />} />
      <Route path='/category/:slug' element={<Category />} />
        */}
    </Routes>
  );
}

export default Router;
