export const SOCIAL_BRANDS = {
  FACEBOOK: 'Facebook',
  TWITTER: 'Twitter',
};

export const SOCIAL = [
  {
    brand: SOCIAL_BRANDS.FACEBOOK,
    name: 'Facebook',
    link: 'https://www.facebook.com/groups/chicagoevertonians/',
  },

  {
    brand: SOCIAL_BRANDS.TWITTER,
    name: 'Twitter',
    link: 'https://twitter.com/evertonchicago',
  },
];
