/* TODO - strapi content */

import styles from './JoinUs.module.scss';

function JoinUs() {
  return (
    <article className={styles.joinUs}>
      <h2>Join us</h2>
      <p>Becoming a Chicago Evertonian couldn&#39;t be easier and is totally free!</p>
      <p>
        Whether you want to just watch games over a few cold drinks at A.J. Hudsons, meet some new
        Toffee friends on social media, or play a more active role in the growth of our Club and
        future events, we&#39;d love to hear from you.
      </p>
      <p>
        For more info, please contact us at:{' '}
        <a href='mailto:info@evertonchicago.com'>info@evertonchicago.com</a> or find us on the
        following social media channels:
      </p>
      <p>
        <a href='https://twitter.com/EvertonChicago' target='_blank' rel='noopener noreferrer'>
          Twitter
        </a>{' '}
        |{' '}
        <a
          href='https://www.facebook.com/groups/chicagoevertonians/'
          target='_blank'
          rel='noopener noreferrer'
        >
          Facebook
        </a>{' '}
        |{' '}
        <a
          href='https://www.instagram.com/chicagoeverton'
          target='_blank'
          rel='noopener noreferrer'
        >
          Instagram
        </a>
      </p>
    </article>
  );
}

export default JoinUs;
