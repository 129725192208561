/* TODO - strapi content */
import { useRef, useState } from 'react';

// Router - routes loaded into main content area
import Router from 'router/Router';

// components
import JoinUs from 'components/organisms/_content/JoinUs/JoinUs';
import Map from 'components/molecules/Map/Map';
import Tweets from 'components/molecules/Tweets/Tweets';

// hooks
import useScrollPosition, { ScrollPosHistoryType } from 'hooks/useScrollPosition';

// theming
import styles from './Main.module.scss';

function Main() {
  // todo - not DRY: see Header.tsx
  const [isHeaderShrunk, setIsHeaderShrunk] = useState<boolean>();
  const headerShrunk = useRef(false);

  const setHeaderShrunkState = (isShrunk: boolean) => {
    headerShrunk.current = isShrunk || false;
    setIsHeaderShrunk(isShrunk);
  };

  useScrollPosition(({ curPos }: ScrollPosHistoryType) => {
    if (curPos) {
      if (curPos.y < 0 && !headerShrunk.current) {
        setHeaderShrunkState(true);
      } else if (curPos.y >= 0 && headerShrunk.current) {
        setHeaderShrunkState(false);
      }
    }
  });

  return (
    <main className={`${isHeaderShrunk ? styles.shrink : ''}`}>
      <h1>Chicago Evertonians. Since 2001.</h1>
      <div className={styles.content}>
        <div className={styles.left}>
          <Router />
          <JoinUs />
        </div>

        <div className={styles.right}>
          <div className={styles.meetUs}>
            <h3>Meet us at A.J. Hudsons for a match</h3>
            <Map />
          </div>
          <div className={styles.tweetFeed}>
            <h3>Follow us on Twitter</h3>
            <Tweets />
          </div>
        </div>
      </div>
    </main>
  );
}

export default Main;
