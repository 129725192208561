// eslint-disable-next-line import/prefer-default-export
export const TRANSLATIONS_SP = {
  home: 'Casa',
  about: 'Nosotros',
  english: 'Inglés',
  french: 'Francés',
  spanish: 'Español',
  welcome: 'Bienvenido a',
  click: 'Clic',
  header: {
    title: 'header route = ',
  },
  footer: {
    desc: 'Portfolio built 2022 by Mike Trakan with React 18.x and TypeScript 4.x',
  },
  404: {
    header: 'Whoops. ',
    subhead: "Not sure if this is the page you're looking for.",
    subhead2: "If so, I don't have it on me. Sorry.",
    cta: "While you're here, want to check out my",
    or: 'or',
    instead: 'instead',
    pages: {
      home: 'home',
      about: 'about',
    },
  },
  errors: {
    fallback: {
      header: 'Oh no! Something went wrong loading this page!',
      error: 'error',
      btnText: 'Forget this ever happened and reset the site',
      btnLabel: 'click to try and reload',
      apology: 'Sorry for this inconvenience!',
    },
  },
};
