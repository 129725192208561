// internationalization
import { useTranslation } from 'react-i18next';

// components
import Button from 'components/atoms/Button/Button';

// types
import { FallbackProps } from 'react-error-boundary';

// theme
import styles from './ErrorBoundaryFallback.module.scss';

/**
 * Catch JS errors anywhere in the child component tree,
 *  log those errors, and display a fallback UI
 */
function ErrorBoundaryFallback({ error, resetErrorBoundary }: FallbackProps) {
  const { t } = useTranslation();
  const { message: errorMessage } = error;

  return (
    <div className={styles.errorBoundary} role='alert'>
      <div>
        <p className={styles.h2}>{t('errors.fallback.header')}</p>
        <p className={styles.h3}>{t('errors.fallback.subhead')}</p>
        <p className={styles.h3}>{t('errors.fallback.apology')}</p>

        <Button
          text={t('errors.fallback.btnText')}
          label={t('errors.fallback.btnLabel')}
          handleClick={resetErrorBoundary}
        />
      </div>

      <div className={styles.errMsg}>
        <span>
          {t('errors.fallback.error')}: <pre>{errorMessage}</pre>
        </span>
      </div>
    </div>
  );
}

export default ErrorBoundaryFallback;
