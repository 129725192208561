// Route Landing Pages
import About from 'components/pages/About/About';
import Home from 'components/pages/Home/Home';

// constants
import APP from 'constants/app';

const Routes = [
  { id: APP.routes.home, path: '/', page: 'Home', component: <Home /> },
  { id: APP.routes.about, path: '/about', page: 'About', component: <About /> },
];

export default Routes;
