// eslint-disable-next-line import/prefer-default-export
export const TRANSLATIONS_EN = {
  home: `Welcome`,
  about: 'Our Story',

  header: {
    welcome: 'Up The Toffees, Chicago!',
  },
  nav: {
    home: 'Welcome',
    about: 'About Us',
  },
  footer: {
    copy: '© 2001 - ',
    desc: 'Chicago Evertonians. All rights reserved.',
  },

  english: 'English',
  french: 'French',
  spanish: 'Spanish',

  click: 'Click',

  404: {
    header: 'Oh dear! ',
    subhead: "The content you're looking for either doesn't exist,",
    subhead2: "or it's been temporarily relegated.",
    cta: "You're safe though!",
    cta2: 'Click here to check out our',
    or: 'or',
    instead: 'content instead',
    pages: {
      home: 'home',
      about: 'about',
    },
  },
  errors: {
    fallback: {
      header: 'Oh dear!',
      subhead: 'Something went wrong loading this page!',
      error: 'error',
      btnText: 'Forget this ever happened and reset the site',
      btnLabel: 'click to try and reload',
      apology: 'Sorry for this inconvenience!',
    },
  },
};
