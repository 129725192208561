// LIGHT BG
import facebook from 'assets/icons/light/facebook.svg';
// todo import instagram from 'assets/icons/light/instagram.svg';
import twitter from 'assets/icons/light/twitter.svg';

// DARK BG
import facebookDark from 'assets/icons/dark/facebook.svg';
// todo import instagramDark from 'assets/icons/dark/instagram.svg';
import twitterDark from 'assets/icons/dark/twitter.svg';

// constants
import { SOCIAL_BRANDS } from 'constants/social';
import { COLORS, SIZES } from 'constants/theme';

// theme
import styles from './SocialIcon.module.scss';

function SocialIcon({
  brand, // 'facebook'
  color = COLORS.LIGHT, // 'dark/light'
  size = SIZES.SMALL, // 'small/large'
  link,
}: {
  brand: string;
  color: string;
  size: string;
  link: string;
}) {
  const icon = () => {
    switch (brand) {
      case SOCIAL_BRANDS.FACEBOOK:
        return color === COLORS.DARK ? facebookDark : facebook;
      case SOCIAL_BRANDS.TWITTER:
        return color === COLORS.DARK ? twitterDark : twitter;
      default:
        return undefined;
    }
  };

  return (
    <div
      id={`icon-${brand}-${color}-${size}`}
      className={`${styles.socialIcon} ${styles[color]} ${styles[size]}`}
      aria-label={brand}
    >
      <a href={link} title={brand} target='_blank' rel='noopener noreferrer'>
        <img src={icon()} alt={brand} />
      </a>
    </div>
  );
}

export default SocialIcon;
