/* TODO - strapi content */
import styles from './About.module.scss';

function About() {
  return (
    <article className={styles.aboutUs}>
      <h2>About Us</h2>

      <p>Chicago Evertonians are the original US and North American Everton FC supporters group.</p>
      <p>
        We were formed in 2001 by Chicagoan Diana McNally, who upon returning from her studies in
        Liverpool, happened to notice a small Everton plaque by a cash register at &#34;Gingers Ale
        House&#34; (Now{' '}
        <a href='https://www.ajhudsonspublichouse.com/' target='_blank' rel='noopener noreferrer'>
          A.J. Hudsons
        </a>
        ) on the north side of Chicago. As it turned out, the plaque and cash box were both property
        of ex-pat and mad Blue, Jamie Hale.
      </p>
      <p>
        United by their love for the Toffees (and the discovery that there were no EFC supporters
        club in North America at that time), the “EFC Supporters Club North America” (EFCSCNA) was
        born.
      </p>
      <p>
        The group, now known as Chicago Evertonians, is proud being the longest-standing official
        Everton supporters club in the US and North America. We are also happy to participate in the
        North America Everton Supporters Club network. Together, we work closely with Everton FC and
        with partners all across the Windy City and North America, to celebrate and spread the word
        about our famous and historic English Football Club. UTT!
      </p>
    </article>
  );
}

export default About;
