// components
import SocialIcons from 'components/organisms/SocialIcons/SocialIcons';

// internationalization
import { useTranslation } from 'react-i18next';

import styles from './Footer.module.scss';
import { COLORS, SIZES } from '../../../constants/theme';

function Footer() {
  const { t } = useTranslation();
  return (
    <footer className={styles.Footer}>
      <div className={styles.container}>
        <div className={styles.footerInfo}>
          <p>{`${t('footer.copy')} ${new Date().getFullYear()} ${t('footer.desc')}`}</p>
        </div>
        <SocialIcons size={SIZES.LARGE} color={COLORS.DARK} />
      </div>
    </footer>
  );
}

export default Footer;
