/* TODO - typescript */
import {
  TwitterTimelineEmbed,
  // TwitterShareButton,
  // TwitterFollowButton,
  // TwitterHashtagButton,
  // TwitterMentionButton,
  // TwitterTweetEmbed,
  // TwitterMomentShare,
  // TwitterDMButton,
  // TwitterVideoEmbed,
  // TwitterOnAirButton,
} from 'react-twitter-embed';

// theming
import styles from './Tweets.module.scss';

export default function Tweets() {
  return (
    <div className={styles.tweets}>
      <TwitterTimelineEmbed
        sourceType='profile'
        screenName='EvertonChicago'
        options={{ height: 920 }}
      />
    </div>
  );
}
