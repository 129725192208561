// intl
import { useTranslation } from 'react-i18next';

// components
import NavItem from 'components/molecules/NavItem/NavItem';
import Mascot from 'components/molecules/Mascot/Mascot';

/*
TODO - Strapi Nav
 import NavStrapi from 'components/organisms/NavStrapi/NavStrapi';
*/

// constants
import Routes from 'router/routes';

// theming
import styles from './Navbar.module.scss';

function Navbar({ curRoute }: { curRoute: any }) {
  const { t } = useTranslation();

  const NavItems = Routes.map((route, index) => {
    const { id, path } = route;

    const addMascot = index === Routes.length / 2;

    return addMascot ? (
      <li key={`item-${id}`}>
        <Mascot />
        <div className={styles.NavBtn}>
          <NavItem id={id} text={t(`nav.${id}`)} route={path} active={curRoute === path} />
        </div>
      </li>
    ) : (
      <li key={`item-${id}`}>
        <div className={styles.NavBtn}>
          <NavItem id={id} text={t(`nav.${id}`)} route={path} active={curRoute === path} />
        </div>
      </li>
    );
  });

  return (
    <nav>
      <div className={styles.router}>
        <ul>{NavItems}</ul>
      </div>

      {/*
    <div>
        <NavStrapi />
    </div>
    */}
    </nav>
  );
}

export default Navbar;
