/* todo - typescript */
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Tooltip,
  // useMapEvents,
  // useMap
} from 'react-leaflet';

// theming
import styles from './Map.module.scss';

export default function Map() {
  const locationHudsons = [41.95160426837599, -87.66882384376886];
  const offset = {
    horizontal: -9,
    vertical: 30,
  };

  return (
    <div className={styles.map}>
      <MapContainer center={locationHudsons} zoom={14} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />

        <Marker position={locationHudsons}>
          <Popup>
            Cold Beer. Breakfast. <br /> Up The Toffees!
          </Popup>

          <Tooltip
            direction='bottom'
            offset={[offset.horizontal, offset.vertical]}
            opacity={1}
            permanent
          >
            A.J. Hudson&#39;s Public House
          </Tooltip>
        </Marker>
      </MapContainer>
    </div>
  );
}
