export const SEO_PAGE_TYPES = {
  home: 'HOME',
  about: 'ABOUT',
};

const SEO = {
  type: 'website',
  siteName: 'Chicago Evertonians', // use for title
  siteUrl: 'https://evertonchicago.com/',
  siteDesc: `News, history, photos and merchandise from Chicago Evertonians, North America's original Everton supporters group since 2001.`,
  robots: 'index, follow',

  locale: '',
  charSet: 'utf-8',
  themeColor: '0d82df',
  viewport: 'width=device-width, initial-scale=1',

  imgHt: '640',
  imgWd: '1280',

  twitterHandle: '@evertonchicago',
  twitterCardType: 'summary_large_image', // The card type, which will be one of “summary”, “summary_large_image”, “app”, or “player”.

  [SEO_PAGE_TYPES.home]: {
    imgUrl: 'https://mywebsite.net/assets/opengraph/home.jpg',
    pageTitle: 'Chicago Evertonians',
    pageDesc: 'Home of Chicago Evertonians',
  },
  [SEO_PAGE_TYPES.about]: {
    imgUrl: 'https://mywebsite.net/assets/opengraph/about.jpg',
    pageTitle: 'Chicago Evertonians | About',
    pageDesc: 'About Chicago Evertonians',
  },
};

export default SEO;
