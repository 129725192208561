// router
import { NavLink } from 'react-router-dom';

// theming
import styles from './NavItem.module.scss';

type Props = {
  id: string;
  text: string;
  route: string;
  active?: boolean;
} & typeof defaultProps;

const defaultProps = {
  active: false,
};

/**
 * ## NavItem
 * @group  Molecules
 *
 * @param {string} id
 * @param {string} text - menu link's text
 * @param {string} route - the link route
 * @param {boolean} active - if false, don't render
 *
 * @constructor
 */
function NavItem({ id, text, route, active }: Props) {
  return (
    <NavLink
      className={`${styles.navItem} ${active ? styles.active : ''}`}
      to={route}
      state={{ id }}
      aria-label={text}
    >
      <span className={styles.label}>{text}</span>
    </NavLink>
  );
}
NavItem.defaultProps = defaultProps;
export default NavItem;
