// router
import Routes from 'router/routes';

// internationalization
import { useTranslation } from 'react-i18next';

// components
import { Link } from 'react-router-dom';

// theme
import styles from './404.module.scss';

function PageNotFound404() {
  const { t } = useTranslation();

  const GuideUserToSafety = Routes.map((route, index) => {
    const { id, path, page } = route;

    const punctuate = () => {
      let punctuation = ', ';

      if (index === Routes.length - 2) {
        punctuation = ` ${t('404.or')} `;
      } else if (index === Routes.length - 1) {
        punctuation = ` ${t('404.instead')}.`;
      }

      return punctuation;
    };

    return (
      <span key={id}>
        <Link to={path}>{t(`404.pages.${page.toLowerCase()}`)}</Link>
        {punctuate()}
      </span>
    );
  });

  return (
    <div className={styles.pageNotFound}>
      <div className={styles.head}>
        <p className={styles.h2}>{t('404.header')}</p>
        <p className={styles.h3}>{t('404.subhead')}</p>
        <p className={styles.h3}>{t('404.subhead2')}</p>
      </div>
      <p className={styles.h3}>{`${t('404.cta')}`}</p>
      <p className={styles.h3}>
        {`${t('404.cta2')}`} {GuideUserToSafety}
      </p>
    </div>
  );
}

export default PageNotFound404;
