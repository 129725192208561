// components
import SocialIcon from 'components/molecules/SocialIcon/SocialIcon';

// constants
import { SOCIAL } from 'constants/social';
import { COLORS, SIZES } from 'constants/theme';

// theme
import styles from './SocialIcons.module.scss';

/**
 * @param type = social type: 'STORE' or 'APP'
 * @param color (default dark)
 * @param size (default small)
 * @returns {*}
 * @constructor
 */
function SocialIcons({ color = COLORS.DARK, size = SIZES.SMALL }: { color: string; size: string }) {
  const Icons = SOCIAL.map((data) => {
    const brandString = data.name;
    return (
      <SocialIcon
        key={brandString}
        brand={brandString}
        link={data.link}
        color={color}
        size={size}
      />
    );
  });

  return (
    <article className={styles.socialIcons} aria-label='Social Icons'>
      {Icons}
    </article>
  );
}

export default SocialIcons;
