import React from 'react';

// theme
import styles from './Loading.module.scss';

function Loading() {
  return <div className={styles.loading}>Loading...</div>;
}

export default Loading;
