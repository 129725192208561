/* TODO - strapi content */

import { Link } from 'react-router-dom';
import styles from './Welcome.module.scss';

function Welcome() {
  return (
    <article className={styles.welcome}>
      <h2>Welcome</h2>
      <p>
        Chicago Evertonians is the official Chicago supporters group for the greatest football club
        in England, Everton FC.
      </p>

      <p>
        <Link to='/about'>Formed 20 years ago</Link>, we&#39;re made up of more than 200 proud and
        passionate Evertonians from Chicago and elsewhere in the US, the UK, Europe and the world.
      </p>

      <p>
        We meet up to watch every Everton game, whatever time of day, at the famous{' '}
        <a href='https://www.ajhudsonspublichouse.com/' target='_blank' rel='noopener noreferrer'>
          A.J. Hudson’s bar
        </a>
        . Whether you’re a Chicagoan or a Toffee passing through the Windy City, if you want to hang
        out with fellow Blues, our doors are always open.
      </p>
    </article>
  );
}

export default Welcome;
